import Dashboard from './Dashboard';
//sets the pages display settings
import AuthGuard from '../AuthGuard';
const DashboardConfig = {
  settings: {
    layout: {
      style: 'layout1',
    },
  },
  routes: [
    {
      path: '/dashboard',
      component: () => AuthGuard(<Dashboard />),
    },
  ],
};

export default DashboardConfig;
