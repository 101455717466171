import client from 'client';
import store from 'app/store';
import moment from 'moment';
import { constants } from '../../constants';

export default async function ButtonLog({ button_name,type=null,info=null }) {
  //this function requires an agreed upon or a descriptive button name
  //it will save the button press to the database for debugging needs

  let press_user;
  //console.log('Button Log',store.getState().loadz_auth.AuthReducer.user)

  if (store.getState().loadz_auth.AuthReducer.user?.agent) {
    press_user = store.getState().loadz_auth.AuthReducer.user?.agent.agent_id;
  } else if (store.getState().loadz_auth.AuthReducer.user?.staff) {
    press_user = store.getState().loadz_auth.AuthReducer.user?.staff.staff_u_id;
  } else {
    press_user = store.getState().loadz_auth.AuthReducer.user?.user_id;
  }

  await client.post(`${constants.URLLOCAL}/button/log`, {
    button_name,
    press_date: moment().format(),
    press_user,
    type,
    info
  });
}
