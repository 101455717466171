import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ReactApexChart from 'react-apexcharts';
import { memo, useEffect, useState } from 'react';
import moment from 'moment';
import client from 'client';
import { connect } from 'react-redux';
import { hasPermission } from 'app/main/loadz/utils';
import ManagemenpaymentMethods from '../account/ManagemenpaymentMethods';

const week = [
  moment().day('Sunday').format('MM/DD/YYYY'),
  moment().day('Monday').format('MM/DD/YYYY'),
  moment().day('Tuesday').format('MM/DD/YYYY'),
  moment().day('Wednesday').format('MM/DD/YYYY'),
  moment().day('Thursday').format('MM/DD/YYYY'),
  moment().day('Friday').format('MM/DD/YYYY'),
  moment().day('Saturday').format('MM/DD/YYYY'),
];
function formatDateArray(dateArray) {
  const formattedDates = [];
  dateArray.forEach((dateString) => {
    const date = moment(dateString, 'MM/DD/YYYY');
    const formattedDate = date.format('ddd MM/DD');
    formattedDates.push(formattedDate);
  });
  return formattedDates;
}
const formattedArray = formatDateArray(week);

const editNums = (num) => {
  var temp = num.toLocaleString();
  if (temp.split('.').length == 2) {
    if (temp.split('.')[1].length < 2) {
      temp = temp + '0';
    }
  }
  if (temp.includes(',')) {
    temp = temp.replace(',', '');
  }
  return temp;
};

function ChartCompleted({ refreshGraph, compacct, user }) {
  const [chartData, setChartData] = useState(true);
  const [series, setSeries] = useState([]);
  const [dataMethodPayment, setDataMethodPayment] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        offsetY: -0,
      },
      fill: {
        gradient: {},
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: formattedArray,
        labels: {
          datetimeFormatter: {
            day: 'ddd MM/dd',
          },
        },
      },
      yaxis: {
        decimalsInFloat: 0,
      },
      tooltip: {
        enabled: hasPermission('dashboard'),
        x: {
          format: 'dddd MM/dd',
        },
      },
      legend: {
        show: true,
        offsetY: 10,
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        labels: {
          useSeriesColors: true,
        },
      },
      markers: {},
    },
  });

  const getPaymentMethod = async (userId) => {
    try {
      const response = await client.post('/payments/get-payment-method', {
        id: userId,
        payment_source: 1,
        all_method: true,
      });
      const data = response.data;

      if (data && data.length == 0) {
        setOpen(true);
        setDataMethodPayment(data);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataForChart = async () => {
    try {
      const { user_id, company } = user;
      const { compacctnum } = company;
      const dataForChart = await client.get(`/jobs/get-for-dashboard-graph/${compacctnum}`);
      await getPaymentMethod(user_id);

      const fuelResponse = await client.post(`/mileage/weekly-fuel`, { comp_id: user_id });
      const fuel_data = await fuelResponse.data.day_totals;

      const ticketResponse = await client.post(`/ticket/ticket-cost`, { comp_acct: compacctnum });
      const ticket_data = await ticketResponse.data.day_totals;

      const subconticketResponse = await client.post(`/ticket/subconticket-cost`, {
        comp_acct: compacctnum,
      });
      const subconticket_data = await subconticketResponse.data.day_totals;
      const dataForChartJson = dataForChart.data;

      if (dataForChartJson && dataForChartJson.msg) {
        return;
      }

      let _materialCr = [];
      let _ticketCr = [];
      let _waitTimeCr = [];
      let fuel_costs = [];
      let ticket_costs = [];
      let subconticket_costs = [];

      if (dataForChartJson && dataForChartJson.length > 0) {
        dataForChartJson.forEach((element) => {
          _materialCr.push(element.materialCr ? element.materialCr : 0);
          _ticketCr.push(element.ticketCr ? element.ticketCr : 0);
          _waitTimeCr.push(element.waitTime ? element.waitTime : 0);
        });
      }

      fuel_data.forEach((elem) => {
        fuel_costs.push(editNums(elem.total));
      });

      ticket_data.forEach((elem) => {
        ticket_costs.push(elem.total);
      });

      subconticket_data.forEach((elem) => {
        subconticket_costs.push(elem.total);
      });

      setSeries([
        {
          name: 'Hauling Sales',
          data: _ticketCr,
        },
        {
          name: 'Material Sales',
          data: _materialCr,
        },
        {
          name: 'Wait Time Charges',
          data: _waitTimeCr,
        },
        {
          name: 'Fuel Costs',
          data: fuel_costs,
        },
        {
          name: 'Employee Commissions',
          data: ticket_costs,
        },
        {
          name: 'Owner Operator Commissions',
          data: subconticket_costs ? subconticket_costs : 0,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshGraph.current = getDataForChart;
    getDataForChart();
  }, []);

  if (!chartData) {
    return <></>;
  } else {
    return (
      <>
        {open && <ManagemenpaymentMethods open={open} onClose={setOpen}></ManagemenpaymentMethods>}

        <Card
          className='w-full rounded-none'
          style={{ border: '3px solid #edca33', backgroundColor: '#FFFFFF' }}
        >
          <div className='p-20 pb-0'>
            <Typography className='h2 font-Regular'>
              {moment(week[0]).format('MM/DD/YYYY')} to {moment(week[6]).format('MM/DD/YYYY')}
            </Typography>

            <div className='flex flex-row flex-wrap items-center mt-12'>
              <Typography className='text-48 font-Regular leading-none tracking-tighter'></Typography>
            </div>
          </div>
          <div className='h-300 w-100-p'>
            <ReactApexChart options={data.options} series={series} type='area' height={350} />
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loadz_auth?.AuthReducer?.user,
  };
};

export default memo(connect(mapStateToProps, null)(ChartCompleted));
