import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { map } from "lodash";
import { constants } from '../../../../constants';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";
import moment from "moment";


function ScaleImageDialog({scaleImages,openScaleImageDialog,setOpenScaleImageDialog,setScaleImages}){

		const [images, setImages] = useState()

		useEffect(() => {
			if (!scaleImages) return
			const _scaleImage = scaleImages?.scale_image_timestamp?.length 
				? scaleImages?.scale_image_timestamp 
				: scaleImages?.scale_ticket_image.length 
					? Array.isArray(scaleImages.scale_ticket_image) 
						? map(scaleImages?.scale_ticket_image, i => ({key: i})) : [{key: scaleImages?.scale_ticket_image}]
					: []
                    
            if (typeof _scaleImage === 'string') {
                console.log('is string', _scaleImage)
                
                setImages(JSON.parse(_scaleImage))
            } else if (typeof _scaleImage === 'object') {
                console.log('is object', _scaleImage)
                setImages(_scaleImage)
            }
				// setImages(_scaleImage);
		}, [scaleImages])

    const download = (name, e) => {
        console.log(e);
        fetch(e, {
          method: 'GET',
          headers: {},
        })
          .then((response) => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name + '.png'); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
          .catch((err) => {
            console.log(err);
          });
      };

    return(
        <Dialog
        open={openScaleImageDialog}
        onClose={(e) => {e.stopPropagation(); setOpenScaleImageDialog(false); setScaleImages(null)}}
        maxWidth="md"
        fullWidth
        >
            
            <DialogTitle><Typography>Scale Ticket #: {scaleImages?.tk_scale_no}</Typography></DialogTitle>
            <DialogContent>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-8 md:gap-12 lg:gap-16 pb-8 sm:pb-12 lg:pb-16 items-center justify-center">
                {images?.length &&
                    map(
                    images,
                    (image, index) => (
                        <div 
                            key={index} 
                            className="flex flex-col items-center justify-between rounded-lg border border-gray-300 p-4 h-full"
                        >
                        {/* Image container */}
                        <div
                            className="cursor-pointer flex items-center justify-center w-full h-full"
                            onClick={() =>
                            window.open(
                                `${constants.URLLOCAL}/aws/signed?image=${image?.key}`,
                                '_blank'
                            )
                            }
                        >
                            <img
                            src={`${constants.URLLOCAL}/aws/signed?image=${image?.key}`}
                            alt="ticket scale image"
                            />
                        </div>

                        {/* Download icon */}
                        <IconButton aria-label="download" size="large"
                            onClick={(e) => {
                                e.stopPropagation();
                                download(
                                `job${scaleImages.job_no}_ticket${scaleImages.tk_no}_scale${scaleImages.tk_scale_no}_${index + 1}`,
                                `${constants.URLLOCAL}/aws/signed?image=${image?.key}`
                                );
                            }}
                        >
                            <DownloadIcon  />
                        </IconButton>
												{image?.timestamp && <p>{moment(image?.timestamp).format('YYYY-mm-DD, h:mm:ss A')}</p>}
                        </div>
                    )
                    )}
                </div>

            </DialogContent>
            <div className="flex justify-center">
            <Button variant="contained" onClick={(e) => {e.stopPropagation(); setOpenScaleImageDialog(false); setScaleImages(null)}}>
                Close
            </Button>
            </div>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

export default ScaleImageDialog;