import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from '@lodash';

const data = {
  id: 'ChartDelayed',
  conversion: {
    value: '$0 Delayed',
    ofTarget: 13,
  },
  series: [
    {
      name: 'Delayed',
      data: [221, 428, 380, 471, 413, 344, 494],
    },
  ],
  options: {
    chart: {
      type: 'area',
      height: '100%',
      sparkline: {
        enabled: true,
      },
    },
    fill: {
      type: 'solid',
      opacity: 0.7,
      colors: ['#e53935'],
    },
    stroke: {
      //width: 1,
      colors: ['#e53935'],
    },
    xaxis: {
      categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    },
    tooltip: {
      followCursor: true,
      theme: 'dark',
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
  },
};

function ChartDelayed(props) {
  const theme = useTheme();
  //const data = _.merge({}, data);

  _.setWith(data, 'options.colors', [theme.palette.primary.main]);

  return (
    <Card className='w-full rounded-20 shadow'>
      <div className='p-20 pb-0'>
        <Typography className='h3 font-Regular'>Tracking Delayed</Typography>

        <div className='flex flex-row flex-wrap items-center mt-12'>
          <Typography className='text-48 font-Regular leading-none tracking-tighter'>
            ${props.qtyDelayed} Delayed
          </Typography>

          <div className='flex flex-col mx-8'>
            <div className='flex items-center'>
              {/* <Typography className="font-Regular" color="textSecondary">
                {data.conversion.ofTarget}%
              </Typography>
              <Typography className="whitespace-nowrap mx-4" color="textSecondary">
                of target
              </Typography> */}
            </div>
          </div>
        </div>
      </div>
      <div className='h-12 w-100-p'>
        {/* <ReactApexChart
          options={data.options}
          series={data.series}
          type={data.options.chart.type}
          height={data.options.chart.height}
        /> */}
      </div>
    </Card>
  );
}

export default ChartDelayed;
