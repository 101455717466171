import clsx from 'clsx';
import Typography from '@mui/material/Typography';

export default function Zero({ value }) {
  return (
    <div
      className={clsx('flex flex-1 flex-col items-center justify-center p-24', false && 'hidden')}
    >
      <Typography className='text-13 sm:text-20 mb-16' color='textSecondary'>
        There are no{' '}
        {value === 'Completed'
          ? 'tickets completed today'
          : value === 'Delayed'
          ? 'delayed tickets'
          : 'tickets in progress'}
      </Typography>
    </div>
  );
}
