import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import { memo, useEffect, useRef, useState } from 'react';

function WidgetNow() {
  const [time, setTime] = useState(new Date());
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(update, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  });

  function update() {
    setTime(new Date());
  }

  return (
    <Paper className=' rounded-none justify-center' style={{ backgroundColor: '#edca33' }}>
      <div className='text-center '>
        <Typography className='text-16 font-semibold' color='#000000'>
          {format(time, 'MMMM')}
        </Typography>
        <Typography className='text-56  font-bold ' color='#000000'>
          {format(time, 'd')}
        </Typography>

        <Typography className=' mt-8 text-16 font-semibold ' color='#000000'>
          {format(time, 'Y')}
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(WidgetNow);
