import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { constants } from '../../../constants';
import { map, slice } from "lodash";

export const ScaleImageThumbnail = ({ scaleImages, onClick }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!scaleImages) return;

    const _scaleImage = scaleImages?.scale_image_timestamp?.length
      ? scaleImages?.scale_image_timestamp
      : scaleImages?.scale_ticket_image?.length
        ? Array.isArray(scaleImages.scale_ticket_image)
          ? map(scaleImages?.scale_ticket_image, (i) => ({ key: i }))
          : [{ key: scaleImages?.scale_ticket_image }]
        : [];

    if (typeof _scaleImage === 'string') {
        console.log('is string', _scaleImage)
        const _image = JSON.parse(_scaleImage)
        setImages(_image.length > 3 ? slice(_image, 0, 3) : _image );
    } else if (typeof _scaleImage === 'object') {
        console.log('is object', _scaleImage)
        setImages(_scaleImage.length > 3 ? slice(_scaleImage, 0, 3) : _scaleImage );
    }
    
  }, [scaleImages]);

  return (
    <AvatarGroup 
      total={4}  
      spacing="medium" 
      onClick={onClick}
      sx={{
        justifyContent: 'center', 
        gap: 0.5,                   
      }}
    >
      {map(images, (image, index) => (
        <Avatar
          key={index}
          alt="scale image"
          src={`${constants.URLLOCAL}/aws/signed?image=${image?.key}`}
          sx={{
            border: '2px solid black !important',
            boxSizing: 'border-box', 
            cursor: 'pointer'
          }}
          
        />
      ))}
    </AvatarGroup>
  );
};
