import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CircularLoader from 'app/main/loadz/components/CircularLoader';
import Input from '@mui/material/Input';
import moment from 'moment-timezone';
import { ReactMultiEmail } from 'react-multi-email';
import { useState } from 'react';

export const RevenueReport = ({
  open,
  setOpen,
  date,
  setDate,
  generateReport,
  loading,
  makeCsv,
  setMakeCsv,
  setDateEnd,
  dateEnd,
  setSendEmails,
  SendEmails,
  message,
}) => {
  const [emails, setEmails] = useState([SendEmails]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setDate(moment().format('YYYY-MM-DD'));
          setOpen(false);
          setMakeCsv(false);
          setDateEnd(moment().add(1, 'days').format('YYYY-MM-DD'));
          setEmails([]);
        }}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle
          className='flex flex-row items-center justify-center align-center p-8 '
          style={{ background: '#edca33' }}
        >
          Daily Revenue Report
        </DialogTitle>
        <DialogContent className='flex flex-col items-center justify-center align-center p-32'>
          <div className='flex flex-row align-center items-center justify-center w-full'>
            <div className='flex flex-col items-center justify-center align-center p-16'>
              <Typography variant='body1'>Date Start</Typography>
              <Input
                type='date'
                value={date}
                style={{ height: '100%', color: 'black', width: '100%' }}
                className='flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 '
                disableUnderline
                fullWidth
                placeholder='Date Range Start'
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>

            <div className='flex flex-col items-center justify-center align-center p-16'>
              <Typography variant='body1'>Date End</Typography>
              <Input
                type='date'
                value={dateEnd}
                style={{ height: '100%', color: 'black', width: '100%' }}
                className='flex ml-8 rounded-4 shadow px-8 py-4 w-3/5 '
                disableUnderline
                fullWidth
                placeholder='Date Range End'
                onChange={(e) => {
                  setDateEnd(e.target.value);
                }}
              />
            </div>
          </div>

          <div style={{ width: '40%' }}>
            <ReactMultiEmail
              placeholder='Input your Email Address'
              emails={emails}
              onChange={(_emails) => {
                setSendEmails(_emails);
                setEmails(_emails);
              }}
              getLabel={(email, index, removeEmail = index) => {
                return (
                  <Chip
                    key={index} // Add a unique key for each email
                    className='mr-4'
                    data-tag-handle
                    label={email}
                    onDelete={() => removeEmail(index)}
                  />
                );
              }}
            />
          </div>

          {message && (
            <div className='flex flex-col items-center justify-center align-center p-16'>
              <Typography style={{ color: 'red' }} variant='body1'>
                {message}
              </Typography>
            </div>
          )}

          <div className='flex flex-row items-center justify-center align-center p-12 w-full'>
            {loading ? (
              <CircularLoader />
            ) : (
              <div className='flex flex-col justify-center align-center items-center'>
                <div className='flex items-center align-center justify-center pb-8'>
                  <Typography variant='body1'>Generate CSV</Typography>
                  <Checkbox
                    value={makeCsv}
                    onChange={(e) => {
                      setMakeCsv(!makeCsv);
                    }}
                  />
                </div>

                <Button
                  variant='contained'
                  onClick={() => {
                    generateReport();
                  }}
                >
                  Generate Report
                </Button>
              </div>
            )}
          </div>
          <div>
            <Typography variant='body1' fontStyle={'italic'}>
              *Ticket values are based on Hauling Unit Invoice Rate
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
