import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { sendTicketEmailData } from '../../main/chat/store/NewInspectionSlice';
import axios from 'axios';
import { constants } from '../../../constants';

const styles = {
  width: '100%',
};

function TicketMultiEmail(props) {
  const dispatch = useDispatch();
  // console.log('props email => ', props);
  let emailData = props && props.data && props.data.customer_email ? props.data.customer_email : '';
  const [emails, setEmails] = useState([emailData]);

  const getUrl = async (key) => {
    if (key) {
      if (key.includes('http')) {
        return key;
      }
      const url = `${constants.URLLOCAL}/aws/get`;
      try {
        const result = await axios.post(url, { key });
        console.log(`url::`, result?.data);
        return result?.data?.data?.fileLocation;
      } catch (error) {
        console.log('error in signature sendEmailTicket error::', error);
      }
    }
    return null;
  };

  const sendEmailData = async () => {
    if (emails && emails.length > 0) {
      let newData = props.data;
      if (props && props.data) {
        newData.logo = props.logo.logoKey;
        newData.newJobDestination = props.newLoc;
        if (newData?.tk_receievedby_mediaurl) {
          newData.tk_receievedby_mediaurl = await getUrl(newData.tk_receievedby_mediaurl);
        }
        if (newData?.scale_ticket_image) {
          newData.scale_ticket_image = []
          if(Array.isArray(newData.scale_ticket_image))
          {
            for(const image of newData.scale_ticket_image)
            {
              const url = await getUrl(image);
              newData.scale_ticket_image.push(url);
            }
          }

          if(!Array.isArray(newData.scale_ticket_image))
          {
            const url = await getUrl(newData.scale_ticket_image);
            newData.scale_ticket_image.push(url);
          }
        }
        if (newData) {
          let data = {
            data: newData,
            ticketEmail: emails,
          };
          dispatch(sendTicketEmailData(data));
        }
      }
    }
    props.emailhandleOpen();
  };

  return (
    <div style={styles}>
      <ReactMultiEmail
        placeholder="Input your Email Address"
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        getLabel={(email, index, removeEmail = index) => {
          return (
            <Chip
              className="mr-4"
              data-tag-handle
              label={email}
              onDelete={() => removeEmail(index)}
            />
          );
        }}
      />
      <div className="text-center">
        <Button
          variant="contained"
          onClick={sendEmailData}
          className="mx-auto mt-8 cursor-pointer"
        >
          Send Email
        </Button>
      </div>
      <br />
    </div>
  );
}

export default TicketMultiEmail;
