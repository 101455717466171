import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';

function CounterCompleted(props) {
  return (
    <Paper
      className='justify-center rounded-none'
      id='completed'
      style={{
        cursor: 'pointer',
        backgroundColor: '#000000',
        border: '3px solid #edca33',
      }}
    >
      <div className='flex items-center'>
        <Typography className='text-16 px-16 font-Regular ' color='#000000'></Typography>
      </div>
      <div className='text-center '>
        {/* <FactCheckIcon sx={{ color: green[800], fontSize: 50 }} aria-label="recipe" /> */}
        <Typography className='text-56 font-bold  text-white '>{props.counter}</Typography>
        <Typography className='text-16 text-yellow-800 font-Regular'>Jobs Completed</Typography>

        <Typography className='text-16 text-white font-Regular'>
          Ticket Count: {props.tickets}
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(CounterCompleted);
