import { useState, useEffect, memo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { constants } from '../../../constants';
import client from '../../../client';
import { Checkbox, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { DateTimePicker } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import new_store from 'app/store';
import { showMessage } from 'app/store/fuse/messageSlice';
import ButtonLog from '../ButtonLog';

const defaultValues = {
  finaldate: '',
  startdate: '',
  idx_trucks_p: '',
  dr_user_id: '',
  company_id: '',
  status: 1,
  csv: false,
};

const schema = yup.object().shape({
  finaldate: yup.string().required('You must enter finaldate '),
  startdate: yup.string().required('You must enter startdate '),
});

function ModalReportTikets({ id, openReport, setOpenReport, data }) {
  const prop_data = data;
  const no_job = id;
  const [open, setOpen] = useState(openReport);
  const [trucks, settrucks] = useState();
  const [drivers, setdrivers] = useState([]);
  const token = localStorage.token;
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setOpen(openReport);
    gettrucks();
    getdrivers();
  }, [openReport]);

  const handleClose = () => {
    setOpen(false);
    setOpenReport(false);
  };

  const gettrucks = async () => {
    const id = prop_data
      ? prop_data.id
      : new_store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    const comp_acct = id;
    const web = 1;
    try {
      const result = await client.post(`/truck/all`, { comp_acct, web });
      settrucks(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getdrivers = async (data) => {
    const comp_acct = prop_data
      ? prop_data.compacctnum
      : new_store.getState().loadz_auth?.AuthReducer?.user?.company?.compacctnum;
    let compacctnum = comp_acct;
    if (id) {
      try {
        const driver = await client.post(`/props/get-drivers`, { compacctnum });
        const _jsonGetdriver = await driver.data;
        setdrivers(_jsonGetdriver.drivers);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const reporttickets = async (data) => {
    const comp_acct = prop_data
      ? prop_data.compacctnum
      : new_store.getState().loadz_auth?.AuthReducer?.user?.company?.compacctnum;
    data.company_id = comp_acct;
    if (id) {
      try {
        ButtonLog({ button_name: 'csv_tickets_report', type: 'job', info: `job: ${no_job}, `+JSON.stringify(data) });
      } catch (error) {
        console.log('Button Log Error: Ignore: ',error);
        
      }

      try {
        const report = await fetch(`${constants.URLLOCAL}/jobs/report-tickets/${no_job}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
          body: JSON.stringify(data),
        });

        if (report.status == 204) {
          _showMessage(
            'Sorry, no information was found based on the filters or data you provided. Please adjust your criteria and try again.',
            'error'
          );
          return;
        }

        const blob = await report.blob();
        let filename = ``;
        if (blob.type == 'text/csv') {
          filename = 'report_tickets.csv';
        } else {
          filename = 'report_tickets.xlsx';
        }

        const file = window.URL.createObjectURL(blob);
        saveAs(file, filename);
      } catch (error) {
        console.log(error);
      }
    }
  };

  function onSubmit(data) {
    reporttickets(data);
  }

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    );
  };

  return (
    <Dialog
      maxWidth="md"
      className="job-notes batch-dialog"
      open={openReport}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Job Tickets Report</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          style={{ color: 'black', backgroundColor: 'white' }}
          className="batch-dialog-form"
        >
          <div className="flex -mx-6 margin-x-0 stdate-fdate">
            <FormControl
              className="mt-8 mb-16 mx-4 margin-x-0 stdate"
              required
              fullWidth
            >
              <FormLabel style={{ color: 'black' }}>Start Date: </FormLabel>
              <Controller
                name="startdate"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    className="mt-8 mb-16 mx-4 margin-x-0"
                    value={value}
                    onChange={onChange}
                    renderInput={(_props) => (
                      <TextField
                        label="insurance expiry"
                        className="mt-8 mb-16 mx-4 margin-x-0"
                        {..._props}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <FormControl
              className="mt-8 mb-16 mx-4 margin-x-0 fdate"
              required
              fullWidth
            >
              <FormLabel style={{ color: 'black' }}>Final Date: </FormLabel>
              <Controller
                name="finaldate"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    className="mt-8 mb-16 mx-4 margin-x-0"
                    value={value}
                    onChange={onChange}
                    renderInput={(_props) => (
                      <TextField
                        required
                        className="mt-8 mb-16 mx-4 margin-x-0"
                        {..._props}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="stdate-fdate">
            <FormControl
              className="mt-8 mb-16"
              fullWidth
            >
              <FormLabel>Status: </FormLabel>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                  >
                    <MenuItem
                      key="3"
                      value={'3'}
                    >
                      progress
                    </MenuItem>
                    <MenuItem
                      key="2"
                      value={'2'}
                    >
                      delayed
                    </MenuItem>
                    <MenuItem
                      key="1"
                      value={'1'}
                    >
                      completed
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl
              className="mt-8 mb-16 mx-4 margin-x-0"
              fullWidth
            >
              <FormLabel>Trucks: </FormLabel>
              <Controller
                name="idx_trucks_p"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                  >
                    {trucks.map((field) => (
                      <MenuItem
                        value={field.idx_trucks_p}
                        key={field.idx_trucks_p}
                      >
                        {field.t_no_truck}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl
              className="mt-8 mb-16 mx-4 margin-x-0"
              fullWidth
            >
              <FormLabel>Driver: </FormLabel>
              <Controller
                name="dr_user_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                  >
                    {drivers.map((field) => (
                      <MenuItem
                        value={field.dr_user_id}
                        key={field.dr_user_id}
                      >
                        {field.user_fname}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl>
              <FormLabel>csv: </FormLabel>
              <Controller
                name="csv"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    name="csv"
                  />
                )}
              />
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions style={{ backgroundColor: '#edca33' }}>
          <Button
            style={{ color: 'white', backgroundColor: 'black' }}
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            style={{ color: 'white', backgroundColor: 'black' }}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Report
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default memo(ModalReportTikets);
