import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ButtonLog from 'app/main/ButtonLog';

 function RetroactiveUpdateModal({conditional,open ,setOpen, billingmessage,changinginvoices,billingchanges,handleUpdateJob, confirmopen,setConfirmOpen,CloseMainOpenConfirm}) {
   const dispatch = useDispatch();
   const [massage,setmassage] = useState(true)
   const [recalc,setrecalc] = useState(false)
   const [sendemailslater,setSendEmailsLater] = useState(true)

   
//#edca33
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        }}
        classes={{
          paper: 'm-24',
        }}
        open={open}
        onClose={ () => setOpen(false)}
        maxWidth={'sm'}
      > 
      <DialogContent className='p-52'> 
        <DialogContentText color={"black"}>
          {
            confirmopen ? 
            <div className='flex flex-col text-center justify-center'>
              {/* <div className='font-medium text-lg'></div> */}
              <div className="text-center">
              <svg width="60" height="60" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6132 6.9432C18.0893 4.35227 21.9105 4.35226 23.3866 6.9432L36.1735 29.3874C37.6081 31.9055 35.7408 35 32.7868 35H7.21306C4.25902 35 2.39176 31.9055 3.82637 29.3874L16.6132 6.9432ZM21.6665 28.3333C21.6665 29.2538 20.9203 30 19.9998 30C19.0793 30 18.3332 29.2538 18.3332 28.3333C18.3332 27.4129 19.0793 26.6667 19.9998 26.6667C20.9203 26.6667 21.6665 27.4129 21.6665 28.3333ZM21.2498 15C21.2498 14.3096 20.6902 13.75 19.9998 13.75C19.3095 13.75 18.7498 14.3096 18.7498 15V23.3333C18.7498 24.0237 19.3095 24.5833 19.9998 24.5833C20.6902 24.5833 21.2498 24.0237 21.2498 23.3333V15Z" fill="#FF1414"/>
                </svg>
              </div>
              <Typography variant='h3' className="font-600 text-red" >Are You Sure ?</Typography>
              <div>
                { massage ?
                  <p className="text-black font-500">Apply changes going Forward</p>
                  : 
                  <p className="text-black font-500">Apply changes will recalculate and resend historic records</p>
                  }
              </div>
            </div>
            :
            <div className='flex flex-row'>
              <div className='pr-10'>
                {/* <InfoOutlinedIcon sx={{color: 'red', fontSize:'40px'}} /> */}
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6132 6.9432C18.0893 4.35227 21.9105 4.35226 23.3866 6.9432L36.1735 29.3874C37.6081 31.9055 35.7408 35 32.7868 35H7.21306C4.25902 35 2.39176 31.9055 3.82637 29.3874L16.6132 6.9432ZM21.6665 28.3333C21.6665 29.2538 20.9203 30 19.9998 30C19.0793 30 18.3332 29.2538 18.3332 28.3333C18.3332 27.4129 19.0793 26.6667 19.9998 26.6667C20.9203 26.6667 21.6665 27.4129 21.6665 28.3333ZM21.2498 15C21.2498 14.3096 20.6902 13.75 19.9998 13.75C19.3095 13.75 18.7498 14.3096 18.7498 15V23.3333C18.7498 24.0237 19.3095 24.5833 19.9998 24.5833C20.6902 24.5833 21.2498 24.0237 21.2498 23.3333V15Z" fill="#FF1414"/>
                </svg>
              </div>
              <div className='text-red font-500 text-14' >{billingmessage}</div>
            </div>
          }
        </DialogContentText>
        {
          confirmopen ? 
          <Box
            sx={{
              paddingTop: 2,
              display: 'flex',
              flexDirection: 'row',
              m: 'auto',
              width: 'fit-content',
            }}>
            {/* <div className="absolute top-0 left-0 right-0 m-auto">
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25ZM15.9596 34.0404C15.569 33.6499 15.569 33.0168 15.9596 32.6262L23.5858 25L15.9596 17.3738C15.569 16.9832 15.569 16.3501 15.9596 15.9596C16.3501 15.569 16.9832 15.569 17.3738 15.9596L25 23.5858L32.6262 15.9596C33.0168 15.569 33.6499 15.569 34.0404 15.9596C34.431 16.3501 34.431 16.9832 34.0404 17.3738L26.4142 25L34.0404 32.6262C34.431 33.0168 34.431 33.6499 34.0404 34.0404C33.6499 34.431 33.0168 34.431 32.6262 34.0404L25 26.4142L17.3738 34.0404C16.9832 34.431 16.3501 34.431 15.9596 34.0404Z" fill="#FF1414"/>
              </svg>
            </div>   */}
            <Button 
              sx={{color:'#edca33'}}
              variant='contained'
              onClick={(e) => {
              recalc == true ? ButtonLog({type:'job',button_name:'job_retroactive_update'}) : ButtonLog({type:'job',button_name:'job_update_going_forward'})
              e.stopPropagation();  
              setOpen(false);
              recalc == true ? handleUpdateJob(true,billingchanges.og_billing,billingchanges.default_billing,billingchanges.special_billing,sendemailslater,conditional) : handleUpdateJob()
            }}>
              <Typography fontWeight={'bold'} color={'black'}>{`YES`}</Typography>
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button 
              variant='contained'
              sx={{color:'#edca33'}}
              onClick={
              (e) => {
                e.stopPropagation(); 
                setConfirmOpen(false);
                }} >
                <Typography fontWeight={'bold'} color={'black'}>NO</Typography>
            </Button>
          </Box>
          : 
          <Box
            sx={{
              paddingTop: 2,
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}>
            {/* <div className='flex flex-row'>
              <Button 
              className='rounded'
              sx={{color:'#edca33'}}
              variant='contained'
              onClick={(e) => {
              e.stopPropagation();  
              setConfirmOpen(true)
              //setOpen(false);
              //handleUpdateJob(true,billingchanges.og_billing,billingchanges.default_billing,billingchanges.special_billing)
              
              }}>
                <Typography fontWeight={'bold'} color={'black'}>{`APPLY CHANGES TO ALL (${changinginvoices}  Invoice(s) will be recalculated and resent)`}</Typography>
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button 
                className='rounded'
                variant='contained'
                sx={{color:'#edca33'}}
                onClick={
                (e) => {
                  e.stopPropagation(); 
                  // setOpen(false);
                  // handleUpdateJob()
                  setConfirmOpen(true)
                  }} >
                  <Typography fontWeight={'bold'} color={'black'}>APPLY CHANGES GOING FORWARD</Typography>
              </Button>
             </div> */}

              <div className='outerbox flex flex-row gap-10'>
                <div className='innerbox-1 w-1/2 text-left p-20 relative border-1 border-grey-200 rounded-12 shadow-lg' style={{height:"200px"}}>
                <Typography fontWeight={'bold'} color={'black'}>{`APPLY CHANGES TO ALL`}</Typography>
                <Typography fontWeight={'bold'} color={'black'}>{`(${changinginvoices?.comp} Company Invoice(s),`}</Typography>
                <Typography fontWeight={'bold'} color={'black'}>&nbsp;&nbsp;{`${changinginvoices?.sub} Subcontractor Invoice(s),`}</Typography>
                <Typography fontWeight={'bold'} color={'black'}>&nbsp;&nbsp;{`${changinginvoices?.op} Owner Operator Invoice(s)`}</Typography>
                <Typography fontWeight={'bold'} color={'black'}>{`will be recalculated and resent)`}</Typography>




                <div className='w-full flex justify-end'>
                  <Button 
                  className='rounded absolute bottom-20 left-20'
                  sx={{color:'#edca33'}}
                  variant='contained'
                  onClick={(e) => {
                  e.stopPropagation();  
                  setConfirmOpen(true)
                  setmassage(false)
                  //setOpen(false);
                  //handleUpdateJob(true,billingchanges.og_billing,billingchanges.default_billing,billingchanges.special_billing)
                  setrecalc(true)
                  }}>
                    <Typography fontWeight={'bold'} color={'black'}>APPLY</Typography>
                  </Button>
                </div>
                </div>
                <div className='innerbox-2 w-1/2 text-left p-20 relative border-1 border-grey-200 rounded-12 shadow-lg' style={{height:"200px"}}>
                <Typography fontWeight={'bold'} color={'black'}>APPLY CHANGES GOING FORWARD</Typography>
                <div className='w-full flex justify-end'>
                  <Button 
                    className='rounded absolute bottom-20 left-20'
                    variant='contained'
                    sx={{color:'#edca33'}}
                    onClick={
                    (e) => {
                      e.stopPropagation(); 
                      // setOpen(false);
                      // handleUpdateJob()
                      setConfirmOpen(true)
                      setmassage(true)
                      setrecalc(false)
                      }} >
                      <Typography fontWeight={'bold'} color={'black'}>APPLY</Typography>
                  </Button>
                </div>
                </div>
              </div>
              <div className='flex flex-row mt-32'>
                <div className='flex items-center w-full'>
                    <ReportProblemOutlinedIcon sx={{color: 'red', fontSize:'15px'}} />
                    <Typography fontWeight={'bold'} color={'red'} >All changes are final</Typography>
                </div>
                <div className='flex justify-end items-center w-full'>
                  <Checkbox checked={sendemailslater} onChange={() => setSendEmailsLater(!sendemailslater)} sx={{length:15,width:15}} /> 
                  <Typography  sx={{paddingLeft:1,fontWeight:'bold'}}>Send Notification Later</Typography>
                </div>
              </div>
          </Box>
        }
        
      </DialogContent>
    
      </Dialog>



    </div>
        
     
  
   
  );

 }
 
 export default RetroactiveUpdateModal;

