import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ReactApexChart from 'react-apexcharts';
import { constants } from '../../../../constants';
import { memo, useEffect, useState } from 'react';
import axios from 'axios';
import store from '../../ID_store/store';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { blue, green, orange, red, grey } from '@mui/material/colors';

const week = [
  moment().day('Sunday').format('MM/DD/YYYY'),
  moment().day('Monday').format('MM/DD/YYYY'),
  moment().day('Tuesday').format('MM/DD/YYYY'),
  moment().day('Wednesday').format('MM/DD/YYYY'),
  moment().day('Thursday').format('MM/DD/YYYY'),
  moment().day('Friday').format('MM/DD/YYYY'),
  moment().day('Saturday').format('MM/DD/YYYY'),
];

// let data = {

//   series: [],
//   options: {
//     chart: {
//       height: 350,
//       type: 'area'
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'smooth'
//     },
//     xaxis: {
//       type: 'datetime',
//       categories: week
//     },
//     tooltip: {
//       x: {
//         format: 'dd/MM/yy HH:mm'
//       },
//       tooltip: {
//         theme: "light",
//         followCursor: true,
//         //enabledOnSeries: true,
//         //fillSeriesColor:true,
//         style: {
//           fontSize: '13px',
//           fontFamily: 'poppins'
//         },
//       },
//     },
//   },

// };

function ChartCompleted({ refreshGraph }) {
  const { compname, comp_email, comp_acct } = store.getState();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [chartData, setChartData] = useState(true);
  const [series, setSeries] = useState([]);

  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
      },
      fill: {
        // type: 'gradient',
        gradient: {
          // opacityFrom: 0.6,
          // opacityTo: 0.8,
        },
        colors: [green[500], blue[500], orange[500], grey[900], red[500]],
      },
      dataLabels: {
        enabled: false,
        colors: [green[500], blue[500], orange[500], grey[900], red[500]],
      },
      stroke: {
        curve: 'smooth',
        colors: [green[500], blue[500], orange[500], grey[900], red[500]],
      },
      xaxis: {
        type: 'datetime',
        categories: week,
        labels: {
          datetimeFormatter: {
            day: 'ddd MM/dd',
          },
        },
      },
      tooltip: {
        x: {
          format: 'dddd MM/dd',
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        labels: {
          useSeriesColosr: true,
        },
        markers: {
          fillColors: [green[500], blue[500], orange[500], grey[900], red[500]],
        },
      },
      markers: {
        colors: [green[500], blue[500], orange[500], grey[900], red[500]],
      },
    },
  });

  const token = localStorage.token;

  const getDataForChart = async () => {
    try {
      // setChartData(false)

      const dataForChart = await fetch(`${constants.URLLOCAL}/jobs/get-for-dashboard-graph`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
      });

      const { id } = store.getState();
      const comp_id = id;
      const fuelResponse = await axios.post(
        `${constants.URLLOCAL}/mileage/weekly-fuel`,
        { comp_id },
        { headers: { token: localStorage.getItem('token') } }
      );

      const fuel_data = await fuelResponse.data.day_totals;
      // {[day , total]}

      const ticketResponse = await axios.post(`${constants.URLLOCAL}/ticket/ticket-cost`, {
        comp_id,
      });

      const ticket_data = await ticketResponse.data.day_totals;

      const dataForChartJson = await dataForChart.json();

      if (dataForChartJson.msg) {
        return;
      }

      let _materialCr = [];
      let _ticketCr = [];
      let _waitTimeCr = [];
      let fuel_costs = [];
      let ticket_costs = [];

      dataForChartJson.forEach((element) => {
        _materialCr.push(element.materialCr ? element.materialCr : 0);
        _ticketCr.push(element.ticketCr ? element.ticketCr : 0);
        _waitTimeCr.push(element.waitTime ? element.waitTime : 0);
        // _waitTimeCr.push(0)
      });

      fuel_data.forEach((elem) => {
        fuel_costs.push(elem.total);
      });

      ticket_data.forEach((elem) => {
        ticket_costs.push(elem.total);
      });

      setSeries([
        {
          name: 'Hauling Sales',
          data: _ticketCr,
        },
        {
          name: 'Material Sales',
          data: _materialCr,
        },
        {
          name: 'Wait Time Charges',
          data: _waitTimeCr,
        },
        {
          name: 'Fuel Costs',
          data: fuel_costs,
        },
        {
          name: 'Employee Commissions',
          data: ticket_costs,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshGraph.current = getDataForChart;
    getDataForChart();
  }, []);

  useEffect(() => {
    // [1,2,3].forEach(element => {
    //   document.getElementById(`li${element}`).style.color = theme.palette.primary.main
    // })
    // setChartData(true)
  }, [series]);

  // _.setWith(data, "options.colors", [theme.palette.primary.main]);

  if (!chartData) {
    return <></>;
  } else {
    return (
      <Card
        class='rounded-none'
        className='w-full'
        style={{
          border: '3px solid #edca33',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div className='p-20 pb-0'>
          <Typography className='h2 font-Regular'>
            {week[0]} To {week[6]}
          </Typography>

          <div className='flex flex-row flex-wrap items-center mt-12'>
            <Typography className='text-48 font-Regular leading-none tracking-tighter'>
              {/* {data.conversion.value} */}
            </Typography>

            <div className='flex flex-col mx-8'>
              {/* {data.conversion.ofTarget > 0 && (
                <Icon className="text-green text-20">trending_up</Icon>
              )}
              {data.conversion.ofTarget < 0 && (
                <Icon className="text-red text-20">trending_down</Icon>
              )} */}
              {/* <div className="flex items-center">
                <Typography className="font-Regular" color="textSecondary">
                  {data.conversion.ofTarget}%
                </Typography>
                <Typography
                  className="whitespace-nowrap mx-4"
                  color="textSecondary"
                >
                  of target
                </Typography>
              </div> */}
            </div>
          </div>
        </div>
        <div className='h-300 w-100-p'>
          <ReactApexChart
            options={data.options}
            series={series}
            // type={data.options.chart.type}
            type='area'
            height={250}
          />
        </div>
      </Card>
    );
  }
  // return (
  //   <ReactApexChart options={data.options} series={data.series} type="area" height={350} />
  // )
}

export default memo(ChartCompleted);
