import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';

function CounterProgress(props) {
  return (
    <Paper
      className='rounded-none justify-center w-full'
      id='progress'
      style={{
        cursor: 'pointer',
        backgroundColor: '#000000',
        border: '3px solid #edca33',
      }}
    >
      <div className='flex items-center'>
        <Typography className='text-16 px-16 font-Regular ' color='#000000'></Typography>
      </div>
      <div className='text-center'>
        <Typography className='text-56 font-bold text-white '>{props.counter}</Typography>
        <Typography className='text-16 text-yellow-800 font-Regular'>Jobs In Progress</Typography>

        <Typography className='text-16 text-white font-Regular'>
          Ticket Count: {props.tickets}
        </Typography>
      </div>
    </Paper>
  );
}

export default memo(CounterProgress);
